import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import styled from "styled-components";
import { ApiUrl } from "../../API/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrayingHands, faSpa, faTint } from "@fortawesome/free-solid-svg-icons";


const StyledTabList = styled.div`
  display: flex;
  background-color: #e6e6e6;
  margin: 0;
  padding: 0;
`;

const StyledTab = styled.div`
  flex: 1;
  text-align: center;
  padding: 10px;
  background-color: ${(props) => (props.isSelected ? "#bf0a30" : "#E6E6E6")};
  color: ${(props) => (props.isSelected ? "#f6c93f" : "#bf0a30")};
  font-weight: 500;
  cursor: pointer;
  border: 1px solid transparent;
  transition: background-color 0.3s;
  &:hover {
    background-color: #bf0a30;
    color: #f6c93f;
  }
`;

const StyledTabPanel = styled.div`
  max-height: 1000px;
  overflow-y: hidden;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  color: #333;
  margin-top: 10px;
  transition: overflow-y 0.3s;
  &:hover {
    overflow-y: auto;
  }

  /* Add the Bootstrap scrollbar class */
  &.overflow-auto {
    overflow: auto;
  }
`;

const MyTabComponent = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('from');
  const [selectedTab, setSelectedTab] = useState(0);
  const [galleryImages, setGalleryImages] = useState([]);
  const [servieData, setservieData] = useState([]);
  const [profileData] = useState(null);

  const [specificCommunityData] = useState([]);
  useEffect(() => {
    axios
      .get(`${ApiUrl}/booking/room`)
      .then((response) => {
        const allgallerydata = response?.data?.data?.allgallerydata;
        const allservicedata = response?.data?.data?.services;
        if (allgallerydata) {
          const filteredData = allgallerydata.filter((item) => item.category_id.toString() === id.toString());
          setGalleryImages(filteredData);
          const servicedata = allservicedata.filter((item) => item.category_id.toString() === id.toString());
          setservieData(servicedata[0]);
        } else {
          console.error("No gallery data found in the response");
        }
      })
      .catch((error) => {
        console.error("Error fetching gallery images:", error);
      });
  }, [id]);



  const tabContent = [
    {
      title:
        servieData
          ? servieData?.title?.split("-")[0]
          : "No data",
      content: "This is the content for Profile tab.",
      apiData: profileData,
      render: () => (
        <>
          <section className="light">
            <div className="container">
              <div className="h1 text-center text-dark" id="pageHeaderTitle">
              </div>
              {servieData ? (
                <article className="postcard light blue">
                  <img
                    className="postcard__img"
                    src={servieData.media_url}
                    alt={servieData.title}
                  />
                  <div className="postcard__text t-dark">
                    <h1 className="postcard__title blue">
                      {servieData.title}
                    </h1>
                    <div className="postcard__subtitle small">
                      <time dateTime={servieData.date}>
                        <i className="fa fa-calendar-alt mr-2" />
                        {servieData.date}
                      </time>
                    </div>
                    <div className="postcard__bar" />
                    <div className="postcard__preview-txt"
                      dangerouslySetInnerHTML={{
                        __html: servieData.content,
                      }}
                    />
                    <h4>Amenities :-</h4>
                    <br />
                    <ul className="postcard__tagbox">
                      <li className="tag__item">
                        <i><FontAwesomeIcon icon={faTint} /></i>
                        &nbsp; Drinking Water
                      </li>
                      <li className="tag__item">
                        <i className="fa fa-wifi mr-2" />
                        &nbsp; WiFi
                      </li>
                      <li className="tag__item">
                        <i className="fa fa-snowflake-o mr-2" />
                        &nbsp; Air Conditioning
                      </li>
                      <li className="tag__item">
                        <i className="fa fa-bed mr-2" />
                        &nbsp; Bedroom
                      </li>
                      <li className="tag__item">
                        <i className="fa fa-bath mr-2" />
                        &nbsp; Bathroom
                      </li>
                      <li className="tag__item">
                        <i className="fa fa-camera mr-2" />
                        &nbsp; CCTV Camera
                      </li>
                      <li className="tag__item">
                        <i className="fa fa-cutlery mr-2" />
                        &nbsp; Dinning Hall
                      </li>
                      <li className="tag__item">
                        <i className="fa fa-users mr-2" />
                        &nbsp; Conference Hall
                      </li>
                      <li className="tag__item">
                        <i className="fa fa-video-camera mr-2" />
                        &nbsp; Projector
                      </li>
                      <li className="tag__item">
                        <i className="fa fa-tree mr-2" />
                        &nbsp; Open Space
                      </li>
                      <li className="tag__item">
                        <FontAwesomeIcon icon={faPrayingHands} />
                        &nbsp; Prayer Room
                      </li>
                      <li className="tag__item">
                        <FontAwesomeIcon icon={faSpa} />
                        &nbsp; Meditation Hall
                      </li>
                    </ul>


                  </div>
                </article>
              ) : (
                <div className="col-lg-12">
                  <p>
                    <b>No data Available</b>
                  </p>
                </div>
              )}
            </div>
          </section>

        </>

      ),
    },
    {
      title: "Description",
      content: "This is the content for History tab.",
      render: () => (
        <>
          <h2>{servieData?.title}</h2>
          {servieData?.content ? (
            <div
              dangerouslySetInnerHTML={{
                __html: servieData.content,
              }}
            />
          ) : (
            <p>
              <b>No Data Available</b>
            </p>
          )}
        </>
      ),
    },


    {
      title: "Gallery",
      content: "This is the content for Gallery tab.",
      apiData: galleryImages,
      render: () => (
        <>
          <div className="row">
            {galleryImages && galleryImages.length > 0 ? (
              galleryImages?.map((image, index) => (
                <div key={index} className="col-lg-3 mb-3">
                  <img
                    src={image?.image}
                    alt={""}
                    style={{ width: "100%", height: "190px" }}
                  />
                </div>
              ))
            ) : (
              <div className="col-lg-12">
                <p>
                  <b>No Images Available</b>
                </p>
              </div>
            )}
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      {specificCommunityData?.map((profile) => (
        <div className="home-mother">
          <div className="container" style={{ padding: "30px" }}>
            <h3 className="entry-title motherhouse">
              <a href="/vocation-promotion">{profile?.name?.split("-")[0]}</a>
            </h3>
            <div className="brudcrums">
              <Link to={"/"}>Home &nbsp;»&nbsp; </Link>
              <span className="pagename">{profile?.name?.split("-")[0]}</span>
            </div>
          </div>
        </div>
      ))}
      <br />
      <div className="container">
        <StyledTabList>
          {tabContent?.map((tab, index) => (
            <StyledTab
              key={index}
              isSelected={selectedTab === index}
              onClick={() => setSelectedTab(index)}>
              {tab.title}
            </StyledTab>
          ))}
        </StyledTabList>

        <StyledTabPanel
          className={
            selectedTab !== null && selectedTab === 2
              ? "bootstrap-scrollbar"
              : "bootstrap-scrollbar"
          }>
          {tabContent[selectedTab].render()}
        </StyledTabPanel>
      </div>
      <br />
    </>
  );
};

export default MyTabComponent;
