import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { ApiUrl } from '../API/Api';

const Header = () => {
    const [data , setData] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${ApiUrl}/get/contactDetails`);
               const olddata = localStorage.getItem('contactDetails');
               if(olddata !== response.data.data) {
                localStorage.setItem('contactDetails', JSON.stringify(response.data.data));
                setData(response.data.data);
               }else{
                setData(JSON.parse(olddata));
               }
               
            } catch (error) {
                console.error(error)
            }
        };

        fetchData();
    }, []); 
    return (
        <>
            <header className="top-header" id="top-header-1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-7 col-7">
                            <div className="list-inline">
                                <i className="fa fa-phone text-white" />&nbsp;
                                <a href={`tel:+91-${data ? data?.mobile : '9943339446'}`} className='hoverlinkcolorhead'>
                                    +91-{data ? data?.mobile : '9943339446'}
                                </a>
                                <i className="fa fa-envelope text-white" />&nbsp;
                                <a href={`mailto:${data ? data?.email : 'info@benilde.com'}`} className="d-none-768 hoverlinkcolorhead">
                                    {data ? data?.email : "info@benilde.com"}
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-5 col-5">
                            <ul className="top-social-media pull-right">
                                <li>
                                    <a href="/" className="social-icon facebook"><i className="fa fa-facebook text-white"></i></a>
                                </li>
                                <li>
                                    <a href="/" className="social-icon twitter"><i className="fa fa-twitter text-white"></i></a>
                                </li>
                                <li>
                                    <a href="/" className="social-icon instagram"><i className="fa fa-instagram text-white"></i></a>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </header>
        </>
    )
}

export default Header
