import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import axios from "axios";
import { ApiUrl } from "../API/Api";

const Footer = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [data , setData] = useState(null);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000 * 60);
    return () => clearInterval(intervalId);
  }, []);
  // eslint-disable-next-line
  const [showBanner, setShowBanner] = useState(
    !localStorage.getItem("Benilde-Resource-Centre")
  );
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("currentPage", location.pathname);
  }, [location]);

  const handleAccept = () => {
    localStorage.setItem("Benilde-Resource-Centre", "true");
    setShowBanner(false);
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("Benilde-Resource-Centre");
      localStorage.removeItem("currentPage");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
        try {
          const olddata = localStorage.getItem('contactDetails');
            setData(JSON.parse(olddata));
            const response = await axios.get(`${ApiUrl}/get/contactDetails`);
           if(olddata !== response.data.data) {
            localStorage.setItem('contactDetails', JSON.stringify(response.data.data));
            setData(response.data.data);
           }else{
            setData(JSON.parse(olddata));
           }

           
        } catch (error) {
            console.error(error)
        }
    };

    fetchData();
}, []); 
  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="Benilde-Resource-Centre"
        style={{ background: "black" }}
        buttonStyle={{ color: "#fff", background: "#bf0a30", fontSize: "13px" }}
        onAccept={handleAccept}>
        This website uses cookies to enhance the user experience.
      </CookieConsent>
      <footer className="main-footer clearfix">
        <div className="container">
          <div className="footer-info">
            <div className="row">
              <div className="col-md-3">
                <div className="footer-item">
                  <div className="main-title-2">
                    <h1>About Us</h1>
                  </div>
                  <p className="text-left text-white">
                    The centre is run by the De La Salle brothers whose educational heritage is 350 years old. They serve in 85 countries through schools, colleges, universities and with variety of Projects.
                  </p>
                </div>
              </div>

              <div className="col-md-3">
                <div className="footer-item">
                  <div className="main-title-2">
                    <h1>Contact Us</h1>
                  </div>
                  <ul className="personal-info">
                    <li style={{ lineHeight: "20px " }}>
                      <i className="fa fa-map-marker" />
                      {/* The Manager,<br /> Benilde-Boys Town,
                      <br /> Pulluthu, Nagamalai, <br />
                      Madurai - 625019 */}
                      {data ? data?.address : "The Manager, Benilde-Boys Town, Pulluthu, Nagamalai,  Madurai - 625019"  }
                    </li>
                    <li>
                      <i className="fa fa-envelope" />
                      <a
                        href={`mailto:${data ? data?.email : "info@benilde.com"}`}
                        className="hoverlinkcolor">
                        {data ? data?.email : "info@benilde.com"}
                      </a>
                    </li>
                    <li>
                      <i className="fa fa-phone" />
                      <a href={`tel:${data ? data?.mobile : "9943339446"}`} className="hoverlinkcolor">
                        {data ? data?.mobile : "9943339446"}
                      </a>{" "}
                      /{" "}
                      <a href={`tel:${data ? data?.cell : "6385827007"}`} className="hoverlinkcolor">
                        {data ? data?.cell : "6385827007"}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-3">
                <div className="footer-item">
                  <div className="main-title-2">
                    <h1>Road Map</h1>
                  </div>
                  <ul className="personal-info">
                    <li>
                      <b>Bus No</b > : <i className="fa fa-bus" />26A,C,H 54,B,C68, A
                    </li>
                    <li>
                      <i className="fa fa-phone" />
                      <a href={`tel:${data ? data.mobile : "9994667777"}`} className="hoverlinkcolor">
                        {data ? data.mobile : "9994667777"}
                       
                      </a>
                    </li>
                    <li>
                      <i className="fa fa-mobile" />
                      <a href={`tel:${data ? data.cell : "8526888999"}`} className="hoverlinkcolor">
                        {data ? data.cell : "8526888999"}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>


              <div className="col-md-3">
                <div className="footer-item newsletter">
                  <div className="main-title-2">
                    <h1>Our Location</h1>
                  </div>
                  <div className="newsletter-inner">
                    <iframe
                      src={data ? data.googleMapsUrl : "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3929.7677191615135!2d78.03201857405973!3d9.953274273838998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00cf4f887dde8f%3A0xcb856db27ddbc875!2sBenile%20Resource%20Center%2C%20Boy&#39;s%20Town.%20Madurai!5e0!3m2!1sen!2sin!4v1707826897499!5m2!1sen!2sin"}
                      width="300"
                      title="benilde"
                      height="150"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copy-right">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <p>
                  {" "}
                  © {currentYear}{" "}
                  <a
                    href="https://boscosofttech.com/"
                    target="_blank"
                    rel="noreferrer">
                    <b>BoscoSoft Technologies</b>
                  </a>
                  . All Rights Reserved.
                </p>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="clearfix" />
                <ul className="social-list">
                  <li>
                    <a href="/" className="twitter-bg">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="/" className="facebook-bg">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="/" className="rss-bg">
                      <i className="fa fa-rss" />
                    </a>
                  </li>
                  <li>
                    <a href="/" className="google-bg">
                      <i className="fa fa-google-plus" />
                    </a>
                  </li>
                  <li>
                    <a href="/" className="linkedin-bg">
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
