import React from 'react';
import { faUtensils, faWater } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTint } from '@fortawesome/free-solid-svg-icons';

const Features = () => {
    return (
        <>
            <div className="main-title">
                <h1>Special Features</h1>
                <p>Elevating the ordinary with unexpected delights, making moments unforgettable.</p>
            </div>
            <div className="our-faciltiy-section-2">
                <div className="container">

                    <div className="row wow fadeInUp delay-04s">

                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="services-box">
                                <div className="number">1</div>
                                <div className="icon">
                                    <i>&#x20B9;</i>
                                </div>
                                <div className="detail">
                                    <h3>
                                        Affordable Price
                                    </h3>
                                    <p>
                                        Benilde offers services at an affordable price
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="services-box">
                                <div className="number">2</div>
                                <div className="icon">
                                    <i><FontAwesomeIcon icon={faUtensils} /></i>
                                </div>
                                <div className="detail">
                                    <h3>
                                        Quality Foods
                                    </h3>
                                    <p>
                                        Both non-veg and vegan diets are available
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="services-box">
                                <div className="number">3</div>
                                <div className="icon">
                                    <i><FontAwesomeIcon icon={faTint} /></i>
                                </div>
                                <div className="detail">
                                    <h3>
                                        Purified Water
                                    </h3>
                                    <p>
                                        Purified water facilities directly from Nature
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="services-box">
                                <div className="number">4</div>
                                <div className="icon">
                                    <i><FontAwesomeIcon icon={faWater} /></i>
                                </div>
                                <div className="detail">
                                    <h3>Natural Water</h3>
                                    <p>
                                        Natural water streams at both
                                        ends at a stone throwing distance.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default Features;
