import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
import { ApiUrl } from "../../API/Api";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const ContactUs = () => {
    const [data , setData] = useState(null);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobile: "",
        message: "",
    });

    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        if (e.target) {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        } else {
            setFormData({ ...formData, mobile: e });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (
            !formData.name ||
            !formData.email ||
            !formData.mobile ||
            !formData.message
        ) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "All fields are required!",
            });
            return;
        }

        if (
            !validateName(formData.name) ||
            !validateEmail(formData.email) ||
            !validateMobile(formData.mobile) ||
            !formData.message
        ) {
            return;
        }

        const formattedMobile = formData.mobile.replace(/[^\d]/g, '').slice(-10);

        setLoading(true);

        axios
            .post(`${ApiUrl}/store/contactform`, { ...formData, mobile: formattedMobile })
            .then((response) => {
                Swal.fire({
                    icon: "success",
                    title: "Success!",
                    text: "Contact Request sent successfully",
                });
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "Error sending message",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        setFormData({
            name: "",
            email: "",
            mobile: "",
            message: "",
        });
    }, [loading]);

    const validateName = (name) => {
        if (!/^[A-Za-z\s]+$/.test(name)) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Name should contain only letters!",
            });
            return false;
        }
        return true;
    };

    const validateEmail = (email) => {
        if (!/^\S+@\S+\.\S+$/.test(email)) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Invalid email format!",
            });
            return false;
        }
        return true;
    };

    const validateMobile = (mobile) => {
        if (!/^[0-9\s-]+$/.test(mobile)) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Mobile number should only contain numbers, spaces, or hyphens!",
            });
            return false;
        }
        return true;
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${ApiUrl}/get/contactDetails`);
               const olddata = localStorage.getItem('contactDetails');
               if(olddata !== response.data.data) {
                localStorage.setItem('contactDetails', JSON.stringify(response.data.data));
                setData(response.data.data);
               }else{
                setData(JSON.parse(olddata));
               }
               
            } catch (error) {
                console.error(error)
            }
        };

        fetchData();
    }, []); 

    return (
        <>
            <div className="sub-banner">
                <div className="container">
                    <div className="breadcrumb-area">
                        <h1>Contact Us</h1>
                    </div>
                    <nav className="breadcrumbs">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="/">Home</a>
                            </li>
                            <li className="breadcrumb-item active">Contact Us</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <div className="contact-2 content-area-6">
                <div className="container">
                    <div className="main-title mb-60">
                        <h1>Contact Us</h1>
                        <p>
                            "Connecting Dreams, Crafting Solutions - Your Journey Begins with
                            Us!"
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="contact-info">
                                <h3>Contact Info</h3>
                                <ul className="contact-list">
                                    <li>
                                        <i className="fa fa-map-marker" /> 
                                        {data ? data?.address : "The Manager,<br /> Benilde-Boys Town,<br /> Pulluthu, Nagamalai, <br /> Madurai - 625019"}
                                    </li>
                                    <li>
                                        <a href={`tel:${data ? data?.mobile : "9943339446"}/ ${data ? data?.cell : "6385827007"}`}>
                                            <i className="fa fa-phone" /> {data ? data?.mobile : "9943339446"} / {data ? data?.cell : "6385827007"}
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`mailto:${data ? data?.email : "info@benilde.com"}`}>
                                            <i className="fa fa-envelope" /> {data ? data?.email : "info@benilde.com"}
                                        </a>
                                    </li>
                                </ul>
                                <h3>Follow Us</h3>
                                <ul className="social-list clearfix">
                                    <li>
                                        <a href="/" className="facebook-bg">
                                            <i className="fa fa-facebook" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/" className="twitter-bg">
                                            <i className="fa fa-twitter" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/" className="google-bg">
                                            <i className="fa fa-google-plus" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/" className="rss-bg">
                                            <i className="fa fa-rss" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/" className="linkedin-bg">
                                            <i className="fa fa-linkedin" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-sm-12">
                            <div className="contact-form">
                                <form
                                    onSubmit={handleSubmit}
                                    encType="multipart/form-data"
                                    autoComplete="off">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group name">
                                                <label>Enter your Name <span className="errocolor">*</span></label><br /><br />
                                                <input
                                                    type="text"
                                                    name="name"
                                                    className="form-control"
                                                    placeholder="Name"
                                                    value={formData.name}
                                                    aria-label="Full Name"
                                                    onChange={handleChange}
                                                    autoFocus={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group number">
                                                <label>Enter your Mobile Number <span className="errocolor">*</span></label><br /><br />
                                                <PhoneInput
                                                    country={'in'}
                                                    value={formData.mobile}
                                                    onChange={handleChange}
                                                    placeholder="Mobile Number"
                                                    inputProps={{
                                                        name: 'mobile',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group email">
                                                <label>Enter your Email Address <span className="errocolor">*</span></label><br /><br />
                                                <input
                                                    type="email"
                                                    name="email"
                                                    className="form-control"
                                                    placeholder="Email Address"
                                                    value={formData.email}
                                                    aria-label="Email Address"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group message">
                                                <label>Enter your Message <span className="errocolor">*</span></label><br /><br />
                                                <textarea
                                                    className="form-control"
                                                    name="message"
                                                    value={formData.message}
                                                    placeholder="Write message"
                                                    aria-label="Write message"
                                                    defaultValue={""}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                            <div className="send-btn text-center">
                                                <button
                                                    type="submit"
                                                    className="btn-md btn-theme btn-4 btn-7"
                                                    disabled={loading}>
                                                    {loading ? (
                                                        <i className="fa fa-spinner fa-spin" />
                                                    ) : (
                                                        "Send Message"
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="map">
                    <div className="container">
                        <iframe
                            src={data ? data?.googleMapsUrl : "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3929.7677191615135!2d78.03201857405973!3d9.953274273838998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00cf4f887dde8f%3A0xcb856db27ddbc875!2sBenile%20Resource%20Center%2C%20Boy&#39;s%20Town.%20Madurai!5e0!3m2!1sen!2sin!4v1707826897499!5m2!1sen!2sin"}
                            title="benilde"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactUs;