import React from 'react';
import { Link } from 'react-router-dom';

const HomeRooms = ({ projectdata }) => {
    const cardsPerRow = 3;

    return (
        <>
            <div className="content-area hotel-section bg-grey" style={{ backgroundColor: "rgb(243, 242, 242)" }}>
                <div className="overlay">
                    <div className="container">
                        <div className="main-title">
                            <h1>Benilde Centre Used for</h1>
                            <p>The Benilde Retreat and Conference Complex is open to people of all faiths and backgrounds, but it is especially designed for Christians who want to deepen their relationship with God and others.</p>
                        </div>

                        <div className="row justify-content-center wow fadeInUp delay-04s">
                            {projectdata?.length === 0 ? (
                                <div className="col-12">
                                    <p><b><center>No Data Available</center></b></p>
                                </div>
                            ) : (
                                projectdata?.map((room, index) => (
                                    <div key={index} className={`col-lg-${12 / cardsPerRow} col-md-6 col-sm-12`} id='servicesimage'>
                                        <div className="hotel-box">
                                            <div className="photo-thumbnail">
                                                <div className="photo">
                                                    <img
                                                        src={room?.media_url}
                                                        alt={`room-${index}`}
                                                        className="img-fluid w-100"
                                                    />
                                                    <Link to={`services?serviceid=${room?.id}`}>
                                                        <i className="fa fa-search"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="detail clearfix">
                                                <h3>
                                                    <Link to={`services?serviceid=${room?.id}`}>{room?.title}</Link>
                                                </h3>
                                                <p className="location location-clamp" dangerouslySetInnerHTML={{ __html: room?.content }} />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>

                    </div>
                </div >
            </div >
        </>
    );
};

export default HomeRooms;
