import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../Components/Header";
import MainMenuBar from "./partials/MainMenubar";
import { FaWhatsapp } from "react-icons/fa";
import Footer from "../Components/Footer";
import { ApiUrl } from "../API/Api";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";

const MainLayout = () => {
  const handleWhatsAppClick = () => {
    window.open("https://wa.me/919943339446", "_blank");
  };
  const [loading, setLoading] = useState(true);
  const [Homedata, setHomedata] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedData = localStorage.getItem("HomeData");
        if (storedData) {
          setHomedata(JSON.parse(storedData));
        } else {
          const response = await axios.get(`${ApiUrl}/get/homepagee/sections`);
          localStorage.setItem("HomeData", JSON.stringify(response?.data?.data));
          setHomedata(response?.data?.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
         <ThreeDots
            visible={true}
            height="70"
            width="70"
            color="#bf0a30"
            ariaLabel="benilde-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
      </div>
    );
  }

  return (
    <>
      <Header />
      <MainMenuBar menudata={Homedata?.headermenudata} />
      <div style={{ minHeight: "70vh", padding: "10px" }}>
        <Outlet />
      </div>
      <br />
      <br />
      <br />
      <Footer />
      <div
        style={{
          position: "fixed",
          bottom: "20px",
          left: "20px",
          zIndex: "999",
          border: "3px solid #25D366",
          borderRadius: "50%",
          padding: "8px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <FaWhatsapp
          size={30}
          style={{ cursor: "pointer", color: "#25D366" }}
          onClick={handleWhatsAppClick}
        />
      </div>
    </>
  );
};

export default MainLayout;
